import React, { Component, lazy, Suspense,} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/customIcons/style.css'
import * as serviceWorker from './serviceWorker';
import constFunction from './utils/const.function';
import GHSLogo from '../src/assets/img/GHSLogo/GHSLogo.svg'

const LazyApp = lazy(() => import('./pages/App'));

const SpinnerComponent = () => {
  const logoImage = JSON.parse(localStorage.getItem("companyLogo"));
  let temp = JSON.parse(localStorage.getItem("userinfo"));
    return (<>
      {/* {temp === null ?
      <div className="loading-scr">
        <div className="loading-animation">
          {logoImage !== null ? (
            <img src={logoImage} alt={constFunction.getWebText("COMPANY_LOGO")} className="loadinglogo" />
          ) : (
            // ""
            <img src={GHSLogo} alt={constFunction.getWebText("COMPANY_LOGO")} className="loadinglogo" />
          )}
          <div className="load-bar" />
        </div>
      </div>
      :
      <div className="loading-scr">
        <div className="loading-animation">
        <img src={logoImage} alt={constFunction.getWebText("COMPANY_LOGO")} className="loadinglogo" />
          <div className="load-bar" />
        </div>
      </div>
      } */}
      <div className="loading-scr">
        <div className="loading-animation">
        <img src={GHSLogo} alt={constFunction.getWebText("GHS_LOGO")} className="loadinglogo" />
          <div className="load-bar" />
        </div>
      </div>
      </>
  );
};

class CompanyLogoLoader extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // if (constFunction.isNull(JSON.parse(localStorage.getItem("companyLogo")))) {
    //   constFunction.getCompanyLogo()
    // }
  }
  render() {
    return (
      <>
        <Suspense fallback={<SpinnerComponent />}>
          <LazyApp />
        </Suspense>
      </>
    );
  }
}

ReactDOM.render(<CompanyLogoLoader />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
