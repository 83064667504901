import React from "react";
import ApiProvider from '../services/ApiProvider';
import constVariable from "./const.variable";
import en from "./localization/en.json";
import ar from "./localization/ar.json";
import fr from "./localization/fr.json";
import sr from "./localization/sr.json";
import {browserName, osVersion, osName, fullBrowserVersion, deviceType} from "react-device-detect";
import {toast} from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import constValidation from './const.validation';
import moment from 'moment';
import configFile from '../config.json';
import 'moment-timezone';
import ApiList from '@ApiList';
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
var ifsc = require('ifsc');
const crypto = require('crypto');
// Import css



const body = document.querySelector("body");


class ConstFunction {
  ipAddress = "0.0.0.0";

  showToast = (type, message, onClose) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          onClose != undefined ? onClose() : console.log("");
          window.onkeydown = function (e) {
            if (e.keyCode === 9 || e.keyCode === 13) {
              return true;
            }
          };
        },
      });
    } else if (type === "info") {
      toast.info(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          onClose != undefined ? onClose() : console.log("");
          window.onkeydown = function (e) {
            if (e.keyCode === 9 || e.keyCode === 13) {
              return true;
            }
          };
        },
      });
    } else if (type === "error") {
      window.onkeydown = function (e) {
        if (e.keyCode === 9 || e.keyCode === 13) {
          return false;
        }
      };
      // var errMessage = message === null ? "Error: Contact IT Team" : message;
      toast.error(this.isNull(message) ? "Error: Contact IT Team" : message, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false, //Made it false because the error message is not getting close because autoClose is false
        onClose: () => {
          onClose != undefined ? onClose() : console.log("");
          // window.onkeydown = function(e) {
          //     if (e.keyCode === 9 || e.keyCode === 13) {
          //         return true;
          //     }
          // }
        },
      });
      document.getElementById('toggleBackDrop').click();
    } else if (type === "warn") {
      window.onkeydown = function (e) {
        if (e.keyCode === 9 || e.keyCode === 13) {
          return false;
        }
      };
      toast.warn(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          onClose != undefined ? onClose() : console.log("");
          window.onkeydown = function (e) {
            if (e.keyCode === 9 || e.keyCode === 13) {
              return true;
            }
          };
        },
      });
    }
  };
  getAPIBase() {
    var apiURL = "";
    if (configFile.MODE === "DEV")
      apiURL = configFile.DEV_APIUrl;
    else if (configFile.MODE === "PROD")
      apiURL = configFile.PROD_APIUrl;
    else if (configFile.MODE === "INT")
      apiURL = configFile.INT_APIUrl;
    else if (configFile.MODE === "DEMO")
      apiURL = configFile.DEMO_APIUrl;

    return apiURL;
  }
  getStatusCode(json) {
    return json.header['statusCode'];
  }
  async getStatusMessage(json) {
    return new Promise((resolve, reject) => {
      var statusJson = JSON.parse(localStorage.getItem("status"));
      var message = "Message not found!";
      for (var i = 0; i < statusJson.length; i++) {
        if (statusJson[i].STATUSCODE === json.header.statusCode) {
          message = statusJson[i].MESSAGE;
        }
      }
      resolve(message);
    });
  }
  getTimeDateForDisp(fullDateTime) {
    if (fullDateTime !== null && fullDateTime !== undefined) {
      var dateOnlyString = fullDateTime.split("T");
      var dateOnlyObj = new Date(dateOnlyString[0]);
      var timeOnlyObj = new Date(fullDateTime);

      var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var date = dateOnlyObj.getUTCDate();
      var month = dateOnlyObj.getMonth();
      var year = dateOnlyObj.getFullYear();
      var hours = timeOnlyObj.getHours();
      var minutes = timeOnlyObj.getMinutes();
      var seconds = timeOnlyObj.getSeconds();
      if (date <= 9) {
        date = "0" + date;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds <= 9) {
        seconds = "0" + seconds;
      }
      return date + "-" + mlist[month] + "-" + year + " " + hours + ":" + minutes + ":" + seconds;
    } else {
      return "";
    }
  }
  getDateForDisp(fullDateTime) {
    if (fullDateTime !== null && fullDateTime !== undefined) {
      var mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var date = new Date(fullDateTime).getDate();
      var month = new Date(fullDateTime).getMonth();
      var year = new Date(fullDateTime).getFullYear();

      if (date <= 9) {
        date = "0" + date;
      }

      return date + "-" + mlist[month] + "-" + year;
    } else {
      return "";
    }
  }
  getDateTimeForDisp(fullDateTime) {
    if (fullDateTime !== null && fullDateTime !== undefined) {
      var mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var date = new Date(fullDateTime).getDate();
      var month = new Date(fullDateTime).getMonth();
      var year = new Date(fullDateTime).getFullYear();

      if (date <= 9) {
        date = "0" + date;
      }
      var time = new Date(fullDateTime).toLocaleTimeString();
      return date + "-" + mlist[month] + "-" + year + " " + time;
    } else {
      return "";
    }
  }

  getDateTimeStringToSeconds(fullDateTime) {
    var date = new Date(fullDateTime).getDate();
    var month = new Date(fullDateTime).getMonth();
    var year = new Date(fullDateTime).getFullYear();
    var hours = new Date(fullDateTime).getHours();
    var minutes = new Date(fullDateTime).getMinutes();
    var seconds = new Date(fullDateTime).getSeconds();

    if (date <= 9) {
      date = "0" + date;
    }
    if (minutes <= 9) {
      minutes = "0" + minutes;
    }
    if (seconds <= 9) {
      seconds = "0" + seconds;
    }
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var _initial = months[month] + ' ' + date + ', ' + year + ' ' + hours + ':' + minutes + ':' + seconds;

    var fromTime = new Date(_initial);

    var timeZone = 'Asia/Riyadh';
    moment.tz.setDefault(timeZone);
    const now = moment(moment.tz(moment(), timeZone).format('YYYY-MM-DDTHH:mm:ss.000')).tz(timeZone);

    var toTime = new Date(now._i);

    var differenceTravel = fromTime.getTime() - toTime.getTime();
    return Math.floor((differenceTravel) / (1000));
  }
  dateTimeDiff(date1, date2) {
    if (date1 > date2) { // swap
      var result = this.dateTimeDiff(date2, date1);
      result.years = -result.years;
      result.months = -result.months;
      result.days = -result.days;
      result.hours = -result.hours;
      return result;
    }
    result = {
      years: date2.getYear() - date1.getYear(),
      months: date2.getMonth() - date1.getMonth(),
      days: date2.getDate() - date1.getDate(),
      hours: date2.getHours() - date1.getHours()
    };
    if (result.hours < 0) {
      result.days--;
      result.hours += 24;
    }
    if (result.days < 0) {
      result.months--;
      // days = days left in date1's month,
      //   plus days that have passed in date2's month
      var copy1 = new Date(date1.getTime());
      copy1.setDate(32);
      result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
    }
    if (result.months < 0) {
      result.years--;
      result.months += 12;
    }
    return result;
  }
  getDateTimeNow = (type) => {
    if (type === "current_time") {
      var hours = new Date().getHours();
      var minutes = new Date().getMinutes();
      var seconds = new Date().getSeconds();
      if (hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds <= 9) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    }
    else if (type === "current_datetime") {
      var date = new Date().getDate();
      var month = new Date().getMonth();
      var year = new Date().getFullYear();

      var hours2 = new Date().getHours();
      var minutes2 = new Date().getMinutes();
      var seconds2 = new Date().getSeconds();

      if (date <= 9) {
        date = "0" + date;
      }
      if (month <= 9) {
        month = "0" + month;
      }
      if (hours2 <= 9) {
        hours2 = "0" + hours2;
      }
      if (minutes2 <= 9) {
        minutes2 = "0" + minutes2;
      }
      if (seconds2 <= 9) {
        seconds2 = "0" + seconds2;
      }
      return year + "-" + month + "-" + date + " " + hours2 + ":" + minutes2 + ":" + seconds2;

    } else if (type === "current_date") {
      var date2 = new Date().getDate();
      var month2 = new Date().getMonth() + 1;
      var year2 = new Date().getFullYear();

      if (date2 <= 9) {
        date2 = "0" + date2;
      }
      if (month2 <= 9) {
        month2 = "0" + month2;
      }
      return year2 + "-" + month2 + "-" + date2;
    }
  };
  isSameDate = (date1Full, date2Full, status) => {
    if (date1Full !== undefined && date2Full !== undefined) {
      var data1 = new Date((date1Full.getMonth() + 1) + "/" + date1Full.getDate() + "/" + date1Full.getFullYear());
      var data2 = new Date((date2Full.getMonth() + 1) + "/" + date2Full.getDate() + "/" + date2Full.getFullYear());
      return data1 - data2 === 0;
    }

  };
  getFieldInfo = (state, which, stateName, key) => {
    var stateNameValue = state[stateName + "_mandatory"];
    if (which === "label") {
      for (var i = 0; i < stateNameValue.length; i++) {
        if (stateNameValue[i].key === key) {
          var mandatory = stateNameValue[i].mandatory === true ? "*" : "";
          return stateNameValue[i].title + mandatory;
        }
      }
    } else if (which === "errorFlag") {
      for (var i = 0; i < stateNameValue.length; i++) {
        if (stateNameValue[i].key === key) {
          return stateNameValue[i].error;
        }
      }
    }
  };
  getFieldListInfo = (state, which, stateName, key) => {
    var stateNameValue = state[stateName];
    if (which === "label") {
      for (var i = 0; i < stateNameValue.length; i++) {
        if (stateNameValue[i].key === key) {
          var mandatory = stateNameValue[i].mandatory === true ? "*" : "";
          return stateNameValue[i].title + mandatory;
        }
      }
    } else if (which === "errorFlag") {
      for (var i = 0; i < stateNameValue.length; i++) {
        if (stateNameValue[i].key === key) {
          return stateNameValue[i].error;
        }
      }
    } else if (which === "select") {
      for (var i = 0; i < stateNameValue.length; i++) {
        if (stateNameValue[i].key === key) {
          return stateNameValue[i].select;
        }
      }
    }
  };
  getFieldListInfoIndex = (state, which, stateName, key) => {
    var stateNameValue = state[stateName];
    if (which === "label") {
      var mandatory = stateNameValue[key].mandatory === true ? "*" : "";
      return stateNameValue[key].title + mandatory;
    } else if (which === "errorFlag") {
      return stateNameValue[key].error;
    } else if (which === "select") {
      return stateNameValue[key].select;
    } else if (which === "invalidFlag") {
      return stateNameValue[key].invalid;
    } else if (which === "clabel") {
      var mandatory = stateNameValue[key].mandatory === true ? "*" : "";
      return mandatory + stateNameValue[key].title;
    }
  };
  getISODateFormat = (value) => {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof (value) === "string") {
        value = new Date(value);
      }
      var date = value.getDate();
      var month = value.getMonth() + 1;
      var year = value.getFullYear();
      var hours = value.getHours();
      var minutes = value.getMinutes();
      var seconds = value.getSeconds();
      if (date <= 9) {
        date = "0" + date;
      }
      if (month <= 9) {
        month = "0" + month;
      }
      if (hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + ".000Z";
    } else {
      return "";
    }
  };
  getCountdownFormat = (value) => {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof (value) === "object") {
        value = this.getISODateFormat(value);
      }
      var dateOnlyString = value.split("T");
      var dateOnlyObj = new Date(dateOnlyString[0]);
      var timeOnlyObj = new Date(value);
      var date = dateOnlyObj.getUTCDate();
      var month = dateOnlyObj.getMonth() + 1;
      var year = dateOnlyObj.getFullYear();

      var hours = timeOnlyObj.getUTCHours() - 1; //For Time Zone Asia/Riyadh the Countdown is not working as expected hence deducting an hour
      var minutes = timeOnlyObj.getUTCMinutes();

      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;

      if (date <= 9) {
        date = "0" + date;
      }
      if (month <= 9) {
        month = "0" + month;
      }
      if (hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      return month + " " + date + " " + year + ", " + hours + ":" + minutes + " " + ampm;
    } else {
      return null;
    }
  };
  getCountdownFormatForDashboards = (value) => {
    if (value !== null && value !== undefined && value !== "") {

      if (typeof (value) === "object") {
        value = this.getISODateFormat(value);
      }
      var dateOnlyString = value.split("T");
      var dateOnlyObj = new Date(dateOnlyString[0]);
      var timeOnlyObj = new Date(value);
      var date = dateOnlyObj.getDate();
      var month = dateOnlyObj.getMonth() + 1;
      var year = dateOnlyObj.getFullYear();

      var hours = timeOnlyObj.getUTCHours();
      var minutes = timeOnlyObj.getUTCMinutes();

      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;

      if (date <= 9) {
        date = "0" + date;
      }
      if (month <= 9) {
        month = "0" + month;
      }
      if (hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      return month + "/" + date + "/" + year;
    } else {
      return null;
    }
  };
  ExcelDateToJSDate = (serial) => {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };
  getDateToServer = (date) => {
    if (date !== null && date !== undefined && date !== "") {
      try {
        var dateOnlyObj = date;
        var date = dateOnlyObj.getDate();
        var month = dateOnlyObj.getMonth() + 1;
        var year = dateOnlyObj.getFullYear();
        if (date <= 9) {
          date = "0" + date;
        }
        if (month <= 9) {
          month = "0" + month;
        }
        var hours = new Date().getHours();
        var minutes = new Date().getMinutes();
        return new Date(`${year}-${month}-${date}T${hours}:${minutes}:00.000Z`);
      } catch (e) {
        if (!(date instanceof Date)) {
          date = new Date(date);
        }
        var dateOnlyObj = date;
        var date = dateOnlyObj.getDate();
        var month = dateOnlyObj.getMonth() + 1;
        var year = dateOnlyObj.getFullYear();
        if (date <= 9) {
          date = "0" + date;
        }
        if (month <= 9) {
          month = "0" + month;
        }
        return new Date(`${year}-${month}-${date}`);
      }
    } else {
      return null;
    }
  };
  getDateOnlyToServer = (date) => {
    if (date !== null && date !== undefined) {

      var dateOnlyObj = date;
      var date = dateOnlyObj.getDate();
      var month = dateOnlyObj.getMonth() + 1;
      var year = dateOnlyObj.getFullYear();
      if (date <= 9) {
        date = "0" + date;
      }
      if (month <= 9) {
        month = "0" + month;
      }

      return `${year}-${month}-${date}`;
    } else {
      return null;
    }
  };
  getFieldValidate = (state, type) => {
    var fieldList = state[type + "_mandatory"];
    var errorFound = false;
    for (var i = 0; i < fieldList.length; i++) {
      if (fieldList[i].mandatory) {
        if (state[type][fieldList[i].key] === "" || state[type][fieldList[i].key] === null) {
          fieldList[i].error = true;
          errorFound = true;
        } else {
          fieldList[i].error = false;
        }
      }
    }
    return {
      fieldList: fieldList,
      errorFound: errorFound,
    };
  };
  getEmailFieldValidation = (value) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([._]?\w+)*(\.\w{2,})+$/
    return emailRegex.test(value)
  }
  getFieldValidateDirect = (state, type) => {
    var fieldList = state[type];
    var errorFound = false;
    for (var i = 0; i < fieldList.length; i++) {
      if (fieldList[i].mandatory) {
        if (fieldList[i].error) {
          errorFound = true;
        } else {
          if (fieldList[i].value === "" || fieldList[i].value === null || fieldList[i].countryCode === fieldList[i].value) {
            fieldList[i].error = true;
            errorFound = true;
          } else {
            fieldList[i].error = false;
          }
        }
      }
      // if(fieldList[i].value !== "" && fieldList[i].value !== null && !fieldList[i].error){ // Used to fix another issue that needs to check related to ELIT-110.

      if (fieldList[i].value !== "" && fieldList[i].value !== null) {
        var valueValidate = false;
        if (fieldList[i].type === "poBox") {
          valueValidate = !constValidation.isValidPOBox(fieldList[i].value);
        }
        if (fieldList[i].type === "pinCode") {
          valueValidate = !constValidation.isValidPinCode(fieldList[i].value);
        }
        if (fieldList[i].type === "phoneNumber" && fieldList[i].countryCode !== fieldList[i].value) {
          valueValidate = !constValidation.isValidPhoneNumber(fieldList[i].value, fieldList[i].country, fieldList[i].countryCode);
        }
        if (fieldList[i].type === "email") {
          valueValidate = !constValidation.isValidEmailAddress(fieldList[i].value);
        }
        if (fieldList[i].type === "url") {
          valueValidate = !constValidation.isValidURL(fieldList[i].value);
        }
        if (fieldList[i].type === "userName") {
          valueValidate = !constValidation.isValidUserName(fieldList[i].value);
        }
        // Added this if condition to fix issue ELIT-110.creating shipment with duplicate shipment number
        if (!fieldList[i].error) {
          fieldList[i].error = valueValidate;
        }
        fieldList[i].errorMessage = valueValidate ? " is Invalid" : "";
        errorFound = valueValidate ? true : errorFound;
      }
    }
    return {
      fieldList: fieldList,
      errorFound: errorFound,
    };
  };
  getFieldError = (state, stateName, stateKey) => {
    var fieldList = state[stateName === "fields" ? stateName : stateName + "_mandatory"];
    for (var i = 0; i < fieldList.length; i++) {
      if (fieldList[i].key === stateKey) {
        fieldList[i].error = false;
        if (fieldList[i].errorMessage !== undefined) {
          fieldList[i].errorMessage = "";
        }
        if (fieldList[i].status !== undefined) {
          fieldList[i].status = "";
        }
      }
    }
    return fieldList;
  };
  getFieldErrorNormal = (state, stateName, stateKey) => {
    var fieldList = state[stateName];
    for (var i = 0; i < fieldList.length; i++) {
      if (fieldList[i].key === stateKey) {
        fieldList[i].error = false;
        if (fieldList[i].errorMessage !== undefined) {
          fieldList[i].errorMessage = "";
        }
        if (fieldList[i].status !== undefined) {
          fieldList[i].status = "";
        }
      }
    }
    return fieldList;
  };
  getFieldErrorNormalIndex = (state, stateName, stateKeyIndex) => {
    var fieldList = state[stateName];
    if (fieldList[stateKeyIndex].mandatory) {
      fieldList[stateKeyIndex].error = false;
    }
    if (fieldList[stateKeyIndex].invalid !== undefined) {
      fieldList[stateKeyIndex].invalid = false;
    }
    return fieldList;
  };
  goToTopPage = () => {
    if (body.style.overflow != "hidden") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };
  validateFieldBeforeCall(type, state) {
    if (type === "validate_requirements") {
      if (state.header.twoStage === "Y" && state.header.documentStyle === "STANDARD") {
        var emptySection = false;
        var internalReqTech = true;
        var internalReqComm = true;
        var sectionList = state.sectionList;
        for (var i = 0; i < sectionList.length; i++) {
          if (sectionList[i].requirements.length === 0) {
            emptySection = true;
          } else {
            var foundEnabled = false;
            for (var j = 0; j < sectionList[i].requirements.length; j++) {
              if (sectionList[i].requirements[j].enabledFlag === "Y") {
                foundEnabled = true;
              }
              if (sectionList[i].requirements[j].type === "INTERNAL" && sectionList[i].stage === "TECHNICAL" && sectionList[i].requirements[j].enabledFlag === "Y") {
                internalReqTech = false;
              }
              if (sectionList[i].requirements[j].type === "INTERNAL" && sectionList[i].stage === "COMMERCIAL" && sectionList[i].requirements[j].enabledFlag === "Y") {
                internalReqComm = false;
              }
            }
            if (!foundEnabled) {
              emptySection = true;
            }
          }
        }
      }
      if (emptySection) {
        return {
          error: true,
          message: "Section can't be empty. It has to have least one Requirement"
        };
      } else if (internalReqTech || internalReqComm) {
        return {
          error: true,
          message: "Atlist one Internal Requirement is required in technical and commercial section"
        };
      } else {
        return {
          error: false,
        };
      }
    }
  }

  goToPage(that, page) {
    that.props.history.push(page);
  }
  getBody(json) {
    if(!constFunction.isNull(json.body)) {
      return json.body;
    } else {
      return json;
    }
  }
  async getReqConfig() {
    return {
      browserName: browserName,
      browserVersion: fullBrowserVersion,
      osName: osName,
      osVersion: osVersion,
      deviceType: deviceType,
      ip: this.ipAddress
    };
  }
  getAuthToken(url, body) {
    var userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if (localStorage.getItem("TOKEN") !== undefined && localStorage.getItem("TOKEN") !== null) {
      var token = JSON.parse(localStorage.getItem("TOKEN"));
      var authToken = "";
      if (token.S !== null) {
        authToken = token.S;
      }
      if (token.E !== null) {
        authToken = authToken + "CONN";
        authToken = authToken + token.E;
      }
      if (userInfo !== null) {
        authToken = authToken + "ELIT";
        authToken = authToken + userInfo.token;
      } else {
        if (ApiList.API_URL_FOR_LOGIN === url) {
          authToken = authToken + "ELIT";
          authToken = authToken + body;
        }
      }
      return authToken;
    } else {
      return null;
    }
  }
  getPasswordToken(url, body) {
    var userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if (localStorage.getItem("TOKEN") !== undefined && localStorage.getItem("TOKEN") !== null) {
      var token = JSON.parse(localStorage.getItem("TOKEN"));
      var authToken = "";
      if (token.S !== null) {
        authToken = token.S;
      }
      if (token.E !== null) {
        authToken = authToken + "CONN";
        authToken = authToken + token.E;
      }

      authToken = authToken + "ELIT";
      authToken = authToken + body;

      return authToken;
    } else {
      return null;
    }
  }
  encryptText(text) {
    const ENCRYPTION_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\n' +
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvSQNJd/L6tSgxVUs/9O8\n' +
      'o7Q0ZAbrx+ZiN1l6y2SnZVULZQUbHsp1yjQMS8YYsRZOep9KtfKGFmcOvcOH0ynl\n' +
      'RlcC21BAHXSoC5fKD9Il251x7Kaxl2QIxBURxiLDDY/pIijAc+uDIgEghKbAYMp3\n' +
      'xuEqogeb6FSe6jEkt/i0o696E/iMdT3E1pu6om7TJG/l77OzqWg1InoLGNA86tYN\n' +
      'Asqu2YH6J9XHuKazBHdvtd16Fj408gQRM0U0tgVRyUejvnpUmAYqWcKEnVqICl+o\n' +
      'vdkmNm3HTjWy/RW4O3tkN4RDQmhtpRVzF5lcZ6fMIi1AiJpl36jnuUM6jeeRljhT\n' +
      'OQIDAQAB\n' +
      '-----END PUBLIC KEY-----';
    let key = {
      key: ENCRYPTION_PUBLIC_KEY,
      padding: crypto.constants.RSA_PKCS1_PADDING
    };
    let encryptedMessage = crypto.publicEncrypt(key, Buffer.from(text)).toString('base64');
    return encryptedMessage;
  }
  decryptAESURLJson(encrypted) {
    var ivBuffer = new Buffer([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    var iv = ivBuffer.slice(0, 16);
    let encryptedText = Buffer.from(encrypted, 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from("8479768f48481eeb9c8304ce0a58481e"), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
  encryptAESText = (data) => {
    let iv = crypto.randomBytes(constVariable.AES.IV_LENGTH);
    let cipher = crypto.createCipheriv(constVariable.AES.ALGORITHM, Buffer.from(constVariable.AES.KEY), iv);
    let encrypted = cipher.update(data);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return iv.toString('hex') + ':' + encrypted.toString('hex');
  }
  decryptAESText = (encrypted) => {
    let textParts = encrypted.split(':');
    let iv = Buffer.from(textParts.shift(), 'hex');
    let encryptedText = Buffer.from(textParts.join(':'), 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(constVariable.AES.KEY), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
  getPageActions(pathname, promise) {
    var access = JSON.parse(localStorage.getItem("access"));
    var actionsCode = [];
    var actionsName = [];
    var pageData = {actionsCode: actionsCode, actionsName: actionsName};
    for (var i = 0; i < access.length; i++) {
      for (var j = 0; j < access[i].ACCESS.length; j++) {
        if (access[i].ACCESS[j].PAGES !== undefined) {
          for (var k = 0; k < access[i].ACCESS[j].PAGES.length; k++) {
            if (access[i].ACCESS[j].PAGES[k].PATH === pathname) {
              for (var l = 0; l < access[i].ACCESS[j].PAGES[k].ACTIONS.length; l++) {
                actionsCode.push(access[i].ACCESS[j].PAGES[k].ACTIONS[l].ACTION_TYPE);
                actionsName.push(access[i].ACCESS[j].PAGES[k].ACTIONS[l].ACTION_NAME);
              }
              pageData = {actionsCode: actionsCode, actionsName: actionsName, pageName: access[i].ACCESS[j].PAGES[k].TITLE};
            }
          }
        }
      }
    }


    for (var i = 0; i < access.length; i++) {
      for (var j = 0; j < access[i].ACCESS.length; j++) {
        if (access[i].ACCESS[j].SUB_MENU !== undefined) {
          for (var k = 0; k < access[i].ACCESS[j].SUB_MENU.length; k++) {
            for (var l = 0; l < access[i].ACCESS[j].SUB_MENU[k].PAGES.length; l++) {
              if (access[i].ACCESS[j].SUB_MENU[k].PAGES[l].PATH === pathname) {
                for (var m = 0; m < access[i].ACCESS[j].SUB_MENU[k].PAGES[l].ACTIONS.length; m++) {
                  actionsCode.push(access[i].ACCESS[j].SUB_MENU[k].PAGES[l].ACTIONS[m].ACTION_TYPE);
                  actionsName.push(access[i].ACCESS[j].SUB_MENU[k].PAGES[l].ACTIONS[m].ACTION_NAME);
                }
                pageData = {actionsCode: actionsCode, actionsName: actionsName, pageName: access[i].ACCESS[j].SUB_MENU[k].PAGES[l].TITLE};
              }
            }
          }
        }
      }
    }
    var userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userInfo.roleName.includes("ADMIN")) {
      var actionsCode = ["ADD", "VIEW", "UPDATE", "DELETE"];
      pageData = {actionsCode: actionsCode, actionsName: actionsName, pageName: pageData.pageName};
      if (pathname == "/274f3c41-d2b5-4df8-8527-71e7daa172b4") {
        return new Promise((resolve, reject) => {
          resolve(pageData);
        });
      } else {
        // return new Promise((resolve, reject) => {
        // resolve(pageData);
        // });

        return pageData
      }
    }

    if (pageData.pageName === undefined) {
      var pageBulk = JSON.parse(localStorage.getItem("page_paths"));
      var continueFlow = true;
      if (pageBulk !== null) {
        for (var i = 0; i < pageBulk.length; i++) {
          if (pageBulk[i].PATH === pathname) {
            pageData.pageName = pageBulk[i].TITLE;
            continueFlow = false;
          }
        }
      }

      return new Promise((resolve, reject) => {
        if (continueFlow) {
          var request = {
            "pagePath": pathname,
          };
          ApiProvider.post(ApiList.API_URL_FOR_VIEW_ALL_PRIVILAGE_PAGE, request).then(res => {
            if (res.length > 0) {
              if (pageBulk === null) {
                pageBulk = [];
              }
              pageBulk.push({
                TITLE: res[0].PAGE_DISPLAY_NAME,
                PATH: pathname
              });
              localStorage.setItem("page_paths", JSON.stringify(pageBulk));
              pageData.pageName = res[0].PAGE_DISPLAY_NAME;
            } else {
              pageData.pageName = "";
            }
            resolve(pageData);
          });
        } else {
          resolve(pageData);
        }
      });
    } else {
      if (promise) {
        return new Promise((resolve, reject) => {
          resolve(pageData);
        });
      } else {
        return pageData;
      }
    }
  }
  userAccordingAccess(pathname) {
    var access = JSON.parse(localStorage.getItem("access"));
    var accordionCodes = [];
    var accordingCodeAction = [];

    for (var i = 0; i < access.length; i++) {
      for (var j = 0; j < access[i].ACCESS.length; j++) {
        if (access[i].ACCESS[j].PAGES !== undefined) {
          var thisMenu = false;
          for (var k = 0; k < access[i].ACCESS[j].PAGES.length; k++) {
            if (access[i].ACCESS[j].PAGES[k].PATH === pathname) {
              thisMenu = true;
            }
          }
          if (thisMenu) {
            for (var k = 0; k < access[i].ACCESS[j].PAGES.length; k++) {
              if (access[i].ACCESS[j].PAGES[k].ACCORDION === "Y") {
                accordionCodes.push(access[i].ACCESS[j].PAGES[k].PATH);
                accordingCodeAction.push({
                  ACTIONS: []
                });

                for (var l = 0; l < access[i].ACCESS[j].PAGES[k].ACTIONS.length; l++) {
                  accordingCodeAction[accordingCodeAction.length - 1].ACTIONS.push(access[i].ACCESS[j].PAGES[k].ACTIONS[l].ACTION_TYPE);
                }
              }
            }
          }
        }
      }
    }
    return {Codes: accordionCodes, Actions: accordingCodeAction};
  }
  checkAccordionAction(object, accordion, action) {
    if (accordion === "REFERENCES_ACCORDION"
      || accordion === "CONTACT_ACCORDION"
      || accordion === "FINANCIAL_ACCORDION"
      || accordion === "ADDRESS_ACCORDION"
      || accordion === "PRODUCT_SERVICE_ACCORDION"
      || accordion === "CERTIFICATE_ACCORDION"
      || accordion === "BANK_ACCOUNT_ACCORDION"
      || accordion === "QUESTIONNAIRES_ACCORDION"
      || accordion === "ATTACHMENTS_ACCORDION") {
      var userInfo = JSON.parse(localStorage.getItem("userinfo"));
      if (userInfo.ERP_SupplierID === null) {
        return true;
      }
    }
    return object.Actions[object.Codes.indexOf(accordion)].ACTIONS.includes(action);
  }
  checkAccordion(object, accordion) {
    if (accordion === "REFERENCES_ACCORDION"
      || accordion === "CONTACT_ACCORDION"
      || accordion === "FINANCIAL_ACCORDION"
      || accordion === "ADDRESS_ACCORDION"
      || accordion === "PRODUCT_SERVICE_ACCORDION"
      || accordion === "CERTIFICATE_ACCORDION"
      || accordion === "BANK_ACCOUNT_ACCORDION"
      || accordion === "QUESTIONNAIRES_ACCORDION"
      || accordion === "ATTACHMENTS_ACCORDION") {
      var userInfo = JSON.parse(localStorage.getItem("userinfo"));
      if (userInfo.ERP_SupplierID === null) {
        return true;
      }
    }
    return object.Codes.includes(accordion);
  }
  fileSizeValidate(size) {
    var mbSize = size / 1024 / 1024;
    if (mbSize > constVariable.CONFIGURATION.FILE_SIZE_LIMIT) {
      this.showToast("error", "File size exceeds " + constVariable.CONFIGURATION.FILE_SIZE_LIMIT + " MB");
      return false;
    } else {
      return true;
    }
  }
  validateURL(text) {
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(text)) {
      return true;
    }
    else {
      this.showToast("error", "URL is invalid");
      return false;
    }
  }
  validateFileExtension(extension) {
    if (constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED.includes(extension)) {
      return true;
    } else {
      this.showToast("error", constFunction.getWebText("FILE_TYPE_NOT_ALLOWED"));
      return false;
    }
  }

  getAttachmentExtensionText(type) {
    if (type !== undefined) {
      if (type === "IMAGE") {
        return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED_IMAGE.join(", ");
      } else if (type === "LOGO") {
        return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED_IMAGE_LOGO.join(", ");
      } else {
        return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED.join(", ");
      }
    } else {
      return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED.join(", ");
    }
  }
  getAttachmentExtensionAccept(type) {
    if (type !== undefined) {
      if (type === "IMAGE") {
        return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED_IMAGE.join(",.");
      } else {
        return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED.join(",.");
      }
    } else {
      return constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED.join(",.");
    }
  }

  toolTipAttachmentText(type) {
    return (
      <React.Fragment>
        <Typography color="inherit"><b>{constFunction.getWebText("ATTACHMENTS_SUPPORTED")}</b>:{this.getAttachmentExtensionText(type)}</Typography>
      </React.Fragment>
    );
  }
  // Commented this WebSutup function, since we don't need Instance Connect because there is only one Instance "OpenPortal"
  webSetup() {
    // var url = window.location.href;
    // var arr = url.split("/");
    // var array = arr[2];
    // var arrayy = array.split(":");
    // var portNum = arrayy[1] === "5001" ? "4001" : arrayy[1];
    
    if (localStorage.getItem("TOKEN") === null || localStorage.getItem("TOKEN") === undefined) {
      var request = {
        ApplicationUrl: `${configFile[configFile.MODE + "_URL"]}/`
        // ApplicationUrl: `${configFile.SERVER_URL}:${portNum}/`
      };
      ApiProvider.auth(ApiList.API_URL_FOR_INIT, request).then(res => {
        if (res.length > 0) {
          var token = {
            S: res[0].ENCRYPTED_SQL_TOKEN,
            E: res[0].ENCRYPTED_ERP_TOKEN,
            L: `${configFile[configFile.MODE + "_URL"]}/`
            // L: `${configFile[configFile.MODE + "_URL"]}:${portNum}/`
          };
          localStorage.setItem("TOKEN", JSON.stringify(token));
        }

      });
    }
  }
  downloadFile = (rowData) => {
    this.downloadBase64File(
      rowData.fileData,
      rowData.fileName,
      rowData.fileType);
  };
  downloadBase64File(contentBase64, fileName, mimeType) {
    const linkSource = contentBase64;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }
  getMonthName(monthNumber) {
    var monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthName[monthNumber];
  }
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  getWebText(valueType) {
    var lang = localStorage.getItem("lang") === null ? "EN" : localStorage.getItem("lang");
    if (lang === "EN") {
      return en[valueType];
    } else if (lang === "AR") {
      return ar[valueType];
    } else if (lang === "FR") {
      return fr[valueType];
    } else if (lang === "SR") {
      return sr[valueType];
    } else {
      return en[valueType];
    }
  }
  getWebTextGender() {
    var lang = localStorage.getItem("lang") === null ? "EN" : localStorage.getItem("lang");
    if (lang === "EN") {
      return [
        {title: "Select", value: ""},
        {title: "Mr.", value: "Mr."},
        {title: "Mrs.", value: "Mrs."},
        {title: "Miss.", value: "Miss."},
      ];
    } else {
      return [
        {title: this.getWebText("SELECT_TEXT"), value: ""},
        {title: this.getWebText("MR_TEXT"), value: "Mr."},
        {title: this.getWebText("MRS_TEXT"), value: "Mrs."},
        {title: this.getWebText("MISS_TEXT"), value: "Miss."},
      ];
    }
  }

  getApiResponseMessage(type, messageCode) {
    var messageType = JSON.parse(localStorage.getItem("messageApi"));
    for (var i = 0; i < messageType.length; i++) {
      if (messageCode === messageType[i].COLUMN_NAME)
        return this.showToast(type, messageType[i].COLUMN_VALUE);
    }
  }
  calculatePercentage(amount, percentage) {
    return parseInt(amount) * (parseInt(percentage) / 100);
  }
  clearAllFromLocalStorage = () => {
    localStorage.setItem("document_header", null);
    localStorage.setItem("quote_document_header", null);
    localStorage.setItem("quote_document_lines", null);
    localStorage.setItem("quote_header", null);
    localStorage.setItem("document_section", null);
    localStorage.setItem("quote_section", null);
    localStorage.setItem("document_header_attachment", null);
    localStorage.setItem("document_collaboration", null);
    localStorage.setItem("document_invite_supplier", null);
    localStorage.setItem("qoute_document_header_attachment", null);
    localStorage.setItem("document_currency", null);
    localStorage.setItem("document_lines", null);
    localStorage.setItem("quote_lines", null);
    localStorage.setItem("RFQ_Action_ID", null);
    localStorage.setItem("RFQ_Action", null);
    localStorage.setItem("Quote_Action_ID", null);
    localStorage.setItem("Quote_Action", null);
    localStorage.setItem("Quote_From", null);
    localStorage.setItem("SupplierQuoteLines", null);
  };
  isLogin = () => {
    if (JSON.parse(localStorage.getItem("userinfo")) !== null && JSON.parse(localStorage.getItem("userinfo")) !== undefined) {
      if (JSON.parse(localStorage.getItem("userinfo")).accessLeft !== 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  isLoggedIn() {
    var userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userInfo !== null && userInfo !== undefined && userInfo.accessLeft != -1) {
      var timeDiff = this.loginTimeDiff();
      if (timeDiff.minutes <= constVariable.LOGIN_VALID_TIME_MINUTES && timeDiff.days <= 0 && timeDiff.hours === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loginTimeDiff = () => {
    if (JSON.parse(localStorage.getItem("userinfo")) !== null && JSON.parse(localStorage.getItem("userinfo")).loggedInTime !== null) {
      const today = new Date();
      const endDate = new Date(JSON.parse(localStorage.getItem("userinfo")).loggedInTime);
      const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
      const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
      const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);
      var seconds = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60);
      if (endDate.getTime() === today.getTime()) {
        seconds = 2;
      }
      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  };
  stopTabFunction(e) {
    if (e.keyCode == 9) {
      e.preventDefault();
    }
  };
  AddOrRemoveKeyboardEvent(action) {
    if (action === "ADD") {
      window.addEventListener('keydown', this.stopTabFunction, true);
    } else if (action === "REMOVE") {
      window.removeEventListener('keydown', this.stopTabFunction, true);
    }
  }
  fixedColumnsSetup() {
    document.getElementsByClassName("MuiPaper-root")[0].children[1].children[0].children[0].children[0].scrollLeft = document.getElementsByClassName("MuiPaper-root")[0].children[1].children[0].children[0].children[0].scrollWidth;
  }
  /////////////////////////  Code adeed by PRAVEEN  ////////////////////////
  fileSizeValidateForImage(size) {
    var mbSize = size / 1024 / 1024;
    if (mbSize > constVariable.CONFIGURATION.IMAGE_SIZE_LIMIT) {
      this.showToast("error", "File size exceeds " + constVariable.CONFIGURATION.IMAGE_SIZE_LIMIT + " MB");
      return false;
    } else {
      return true;
    }
  }

  validateFileExtensionForImage(extension) {
    if (constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED_IMAGE.includes(extension)) {
      return true;
    } else {
      this.showToast("error", constFunction.getWebText("FILE_TYPE_NOT_ALLOWED"));
      return false;
    }
  }
  validateFileExtensionForLogo(extension) {
    if (constVariable.CONFIGURATION.FILE_EXTENSION_ALLOWED_IMAGE_LOGO.includes(extension)) {
      return true;
    } else {
      this.showToast("error", constFunction.getWebText("FILE_TYPE_NOT_ALLOWED"));
      return false;
    }
  }
  /////////////////////////  Code adeed by PRAVEEN  ////////////////////////
  isNull = (value) => {
    return value === null || value === undefined || value === ""
  }
  isNullWithZero = (value) => {
    return value === null || value === undefined || value === "" || Number(value) === 0
  }
  ConfirmAlert = (info, onClick) => {
    confirmAlert({
      title: info.title,
      message: info.message,
      buttons: info.options.map((item, key) => ({label: this.isNull(item) ? key === 0 ? "Yes" : "No" : item, onClick: () => onClick(item)})
      )
    });
  };
  IfscValidation = (code) => {
    return ifsc.validate(code)
  }
  getBankDetails = (code, detailor) => {
    ifsc.fetchDetails(code).then(res => detailor(res))
  }
  validateNewCloseDate = (closeDates, newCloseDates) => {
    const closeDate = new Date(closeDates);
    const newCloseDate = new Date(newCloseDates);
    if (closeDate.getFullYear() === newCloseDate.getFullYear() && closeDate.getMonth() === newCloseDate.getMonth() &&
      closeDate.getDate() === newCloseDate.getDate() && closeDate.getHours() === newCloseDate.getHours() &&
      closeDate.getMinutes() === newCloseDate.getMinutes()) {
      return true
    } else {
      return false
    }
  }
  getCompanyLogo = async () => {
    let imageDataUrl = ""
    try {
      // const organization = await ApiProvider.post(ApiList.API_URL_FOR_VIEW_ATTACHMENTS, request);
      let data = {
        language: localStorage.getItem("lang") === null ? "EN" : localStorage.getItem("lang")
    }
   const companiesList = await ApiProvider.post(ApiList.API_URL_FOR_VIEW_ORACLE_HR_OPERATING_UNITS, data)
   if(companiesList.length > 0){
      let request = {
        "refTableID": companiesList[0]?.ORGANIZATION_ID,
        "refTableName": "'ATS_ELIT_COMPANY_LOGO'"
      };
      const res = await ApiProvider.post(ApiList.API_URL_FOR_VIEW_ATTACHMENTS, request);
      if (res.length > 0) {
        const URL = `${ApiList.API_URL_FOR_DOWNLOAD_ATTACHMENT_FROM_S3_BUCKET}${res[0].FILE_DATA}`;
        const bufferData = await ApiProvider.getBufferData(URL, `${res[0].FILE_NAME}`);
        const imageBuffer = Buffer.from(bufferData, 'base64');
        imageDataUrl = `data:image/png;base64,${imageBuffer.toString('base64')}`;
        let previousImageUrl = constVariable.COMPANY_LOGO_URL
        if (previousImageUrl !== imageDataUrl) {
          localStorage.setItem("companyLogo", JSON.stringify(imageDataUrl));
        }
      } else {
        localStorage.setItem("companyLogo",null);
      }
    }
    } catch (error) {
    }
    return imageDataUrl
  }
  
  validateCloseDateExtend = (closeDate, closeDateExtend) => {
    let closeDateDiff = this.dateTimeDiff(new Date(), new Date(closeDate))
    let closeDateExtendDiff = this.dateTimeDiff(new Date(), new Date(closeDateExtend))
    let validCloseDate = closeDateDiff.hours + 2
    if (closeDateExtendDiff.days > closeDateDiff.days) {
      return true
    } else if (closeDateExtendDiff.days >= closeDateDiff.days) {
      // if (closeDateExtendDiff.hours <= 3 || closeDateExtendDiff.hours >= validCloseDate) {
      if (closeDateExtendDiff.hours <= validCloseDate) {
        constFunction.showToast("error", constFunction.getWebText("MORE_THAN_3_HOURS_FROM_NOW"))
        return false
      } else {
        return true
      }
    }
  }
}
const constFunction = new ConstFunction();
export default constFunction;


/**

Version      Jira             Who              Comments
----------------------------------------------------------------------------------------------------------------------
1.1         ELIT-80           Muzamil	         Supplier Registration-Alternative Phone Number.
1.1         ELIT-1            Muzamil	         Error Toast is getting repeated.
1.1         ELIT-78           Muzamil	         Profile Completion-Certificates (UI)- Background Scroll
1.1         Elit-277/ATS-6    Praveen          Remove Mandatory for Supplier Bank Account
**/
