const ApiList = {
  // Auth APIs
  API_URL_FOR_INIT: "auth/init",
  API_URL_FOR_LOGIN: "auth/login",
  API_URL_FOR_REFRESH_TOKEN: "auth/refresh",
  // General APIs
  API_URL_FOR_VIEW_ALL_ASSIGN_APPROVAL: "general/views/ATS_SUP_USER_APPROVAL_LIST_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_APPROVAL_HISTORY_V: "general/views/ATS_ELIT_APPROVAL_HISTORY_V",
  API_URL_FOR_INSERT_OF_ATTACHMENT: "general/attachments/transactions",
  API_URL_FOR_INSERT_OF_ATTACHMENT_S3_BUCKET : "general/attachments/attachmentUpload",
  API_URL_FOR_DOWNLOAD_ATTACHMENT_FROM_S3_BUCKET : "general/attachments/attachmentDownload/",
  API_URL_FOR_VIEW_ATTACHMENTS: "general/views/ATS_ELIT_ATTACHMENTS_V",
  API_URL_FOR_INSERT_UPDATE_DELETE_FOR_APPROVAL: "general/approvalSubmit/transactions",
  API_URL_FOR_VIEW_ALL_LOOKUP: "general/views/ATS_ELIT_LOOKUP_VALUES_V",
  API_URL_FOR_VIEW_ALL_PRIVILAGE_PAGE: "general/rolesAndPrivileges/ATS_ELIT_PRIVILAGE_PAGE_V",
  API_URL_FOR_VIEW_OF_COUNTRY: "general/views/ATS_ELIT_COUNTRY_V",
  API_URL_FOR_VIEW_OF_STATE: "general/views/ATS_ELIT_STATE_V",
  API_URL_FOR_VIEW_OF_CITY: "general/views/ATS_ELIT_CITY_V",
  API_URL_FOR_VIEW_OF_CURRENCY: "general/views/ATS_ELIT_CURRENCY_V",
  API_URL_FOR_VIEW_GENERAL_ATS_SRC_BUYER_PREFERENCES: "general/views/ATS_SRC_BUYER_PREFERENCES_V",
  API_URL_FOR_INSERT_OF_PREFERENCES: "general/preferences/transactions",
  API_URL_FOR_VIEW_ROLES_AND_PRIVILAGES: "general/rolesAndPrivileges/ATS_ELIT_PRIVILAGE_MODULE_V",
  API_URL_FOR_VIEW_OF_PRIVILAGE_ROLE: "general/rolesAndPrivileges/ATS_ELIT_ROLE_V",
  API_URL_FOR_TRANSACTIONS_MODULES: "general/privilegeModules/transactions",
  API_URL_FOR_VIEW_OF_MENU: "general/rolesAndPrivileges/ATS_ELIT_PRIVILAGE_MENU_V",
  API_URL_FOR_TRANSACTIONS_OF_MENU: "general/privilegeMenus/transactions",
  API_URL_FOR_VIEW_OF_MENU_ASSIGNMENT: "general/rolesAndPrivileges/ATS_ELIT_MENU_ASSIGNMENT_V",
  API_URL_FOR_TRANSACTIONS_MENU_ASSIGNMENT: "general/privilegeMenuAssignments/transactions",
  API_URL_FOR_TRANSACTIONS_PAGES: "general/privilegePages/transactions",
  API_URL_FOR_TRANSACTIONS_USERS: "general/privilegeUsers/transactions",
  API_URL_FOR_VIEW_EMPLOYEE_SEARCH: "general/views/ATS_ELIT_EMPLOYEE_SEARCH_V",
  API_URL_FOR_TRANSACTIONS_ACTIONS: "general/privilegeActions/transactions",
  API_URL_FOR_VIEW_ALL_ACTION: "general/rolesAndPrivileges/ATS_ELIT_ACTION_V",
  API_URL_FOR_TRANSACTIONS_ACTION_ASSIGNMENT: "general/privilegeActionsAssignments/transactions",
  API_URL_FOR_VIEW_USER_COUNT: "general/views/ATS_ELIT_DSH_COUNT_USER_V",
  API_URL_FOR_TRANSACTIONS_ROLES: "general/privilegeRoles/transactions",
  API_URL_FOR_TRANSACTIONS_COLUMN_REFERENCE: "general/columnReference/transactions",
  API_URL_FOR_VIEW_OF_REF_ADD_UPDATE_DELETE: "general/columnDetails/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_APPROVAL_DETAILS: "general/approvalDetails/transactions",
  API_URL_FOR_VIEW_ELIT_EMPLOYEE: "general/views/ATS_ELIT_EMPLOYEE_V",
  API_URL_FOR_ANNOUNCEMENT_EMAIL: "general/announcementEmail/transactions",
  // Added By Praveen
  API_URL_FOR_VIEW_APPROVAL_ATTRIBUTES: "approval/views/ATS_ELIT_APPROVAL_ATTRIBUTES_V",
  API_URL_FOR_TRANSACTIONS_APPROVAL_ATTRIBUTES: "approval/attributes/transactions",
  API_URL_FOR_VIEW_LANGUAGES: "general/views/ATS_ELIT_LANGUAGE_V",
  API_URL_GETRATE: "general/getrate/transactions",
  API_URL_FOR_VALIDATE_PASSWORD:"general/views/ELIT_VALIDATE_PASSWORD",
  API_URL_FOR_USERNAME_RETRIEVE:"general/views/USERNAME_RETRIEVE",
  // Supplier APIs 
  API_URL_FOR_INSERT_UPDATE_DELETE_REGISTER: "supplier/registration/transactions",
  API_URL_FOR_INSERT_OF_VALIDATE_SUP_NAME: "supplier/validateSupplier/transactions",
  API_URL_FOR_VIEW_ALL_SUPPLIER_REGISTRATIONS: "supplier/views/ATS_SUP_REGISTRATIONS_V",
  API_URL_FOR_VIEW_OF_SUPPLIER_DETAILS: "supplier/views/ATS_SUPPLIER_DETAILS_V",
  API_URL_FOR_VIEW_OF_ALL_FINANCIAL_DETAILS: "supplier/views/ATS_SUP_FINANCIAL_DETAILS_V",
  API_URL_FOR_VIEW_OF_CONTACTS: "supplier/views/ATS_SUP_CONTACTS_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_SITE: "supplier/views/ATS_SUP_SUPPLIER_SITES_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_ACCOUNT_V: "supplier/views/ATS_SUP_BANK_ACCOUNTS_V",
  API_URL_FOR_VIEW_OF_PRODUCT_SERVICES: "supplier/views/ATS_SUP_PRODUCTS_AND_SERVICES_V",
  API_URL_FOR_VIEW_OF_REFERENCES: "supplier/views/ATS_SUP_REFERENCES_V",
  API_URL_FOR_VIEW_OF_CERTIFICATES: "supplier/views/ATS_SUP_CERTIFICATES_V",
  API_URL_FOR_VIEW_OF_QUESTIONNARIES: "supplier/views/ATS_SUP_QUESTIONNARIES_V",
  API_URL_FOR_VIEW_OF_PRODUCT_CATEGORY: "supplier/views/ATS_ELIT_PRODUCTS_CATEGORY_V",
  API_URL_FOR_VIEW_OF_PRODUCT_SUB_CATEGORY: "supplier/views/ATS_ELIT_PRODUCTS_SUB_CATEGORY_V",
  API_URL_FOR_VIEW_OF_ALL_BANKS: "supplier/views/ATS_ELIT_BANK_V",
  API_URL_FOR_VIEW_OF_ALL_BRANCHES: "supplier/views/ATS_ELIT_BANK_BRANCHES_V",
  API_URL_FOR_INSERT_UPDATE_DELETE_REFERENCE: "supplier/references/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_CONTACT: "supplier/contacts/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_SITES: "supplier/sites/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_CERTIFICATE: "supplier/certificates/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_SUPPLIER_DETAILS: "supplier/details/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_QUESTIONNARIES: "supplier/questionnaire/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_PRODUCT_SERVICES: "supplier/productsAndServices/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_FINANCIAL_DETAILS: "supplier/financial/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_BANK_ACCOUNT: "supplier/bankAccounts/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_BANKS: "supplier/banks/transactions",
  API_URL_FOR_INSERT_UPDATE_DELETE_BRANCHES: "supplier/bankBranches/transactions",
  API_URL_FOR_TRANSACTIONS_INVITED_SUPPLIERS: "supplier/invitedSupplier/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_HEADER: "supplier/headers/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_LINES: "supplier/lineDetails/transactions",
  API_URL_FOR_TRANSACTIONS_RQMT_SUPPLIER: "supplier/requirements/transactions",
  API_URL_FOR_VIEW_SUP_LINES_COST_FACTOR: "supplier/views/ATS_SRC_SUP_LINES_COST_FACTORS_V",
  API_URL_FOR_VIEW_SUP_LINES_PAYTERMS: "supplier/views/ATS_SRC_SUP_LINES_PAY_TERMS_V",
  API_URL_FOR_VIEW_SUP_LINES_ATTRIBUTES: "supplier/views/ATS_SRC_SUP_LINE_ATTRIBUTES_V",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_LINE_ATTRIBUTES: "supplier/attributes/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_LINE_COST_FACTOR: "supplier/costFactors/transactions",
  API_URL_FOR_VIEW_SUPPLIER_HEADERS: "supplier/views/ATS_SRC_SUPPLIER_HEADERS_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_SHIPMENT_V: "supplier/views/ATS_SUP_SHIPMENT_HEADERS_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_INVOICE_V: "supplier/views/ATS_SUP_INVOICE_HEADERS_V",
  API_URL_FOR_VIEW_ALL_SUPPLIER_INVOICE_LINES: "supplier/views/ATS_SUP_INVOICE_LINES_V",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_INVOICE_HEADER: "supplier/invoiceHeaders/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_INVOICE_LINES: "supplier/invoiceLines/transactions",
  API_URL_FOR_VIEW_SUPPLIER_DETAILS: "supplier/views/ATS_SUPPLIER_DETAILS_SEARCH_V",
  API_URL_FOR_VIEW_COLUMN_REFERENCE: "supplier/views/ATS_ELIT_COLUMN_REFERENCE_V",
  API_URL_FOR_VIEW_SUPPLIER_APPROVAL_DETAILS: "supplier/views/ATS_ELIT_APPROVAL_DETAILS_V",
  API_URL_FOR_VIEW_SUPPLIER_APPROVAL_LEVEL: "supplier/views/ATS_APPROVAL_LEVEL_V",
  API_URL_FOR_VIEW_SUPPLIER_LOOKUP_UNIQUE_REFERENCE: "supplier/views/ATS_ELIT_APPROVAL_TYPE",
  API_URL_FOR_VIEW_SUPPLIER_APPROVAL_USER: "supplier/views/ATS_APPROVAL_USER_V",
  API_URL_FOR_VIEW_SUPPLIER_ASN_PO_LINE_DETAILS: "supplier/views/ATS_SUP_ASN_PO_LINE_DETAILS_V",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_SHIPMENT_HEADER: "supplier/shipmentHeaders/transactions",
  API_URL_FOR_TRANSACTIONS_SUPPLIER_SHIPMENT_LINE: "supplier/shipmentLines/transactions",
  API_URL_FOR_VIEW_ATS_SUP_SHIPMENT_LINES: "supplier/views/ATS_SUP_SHIPMENT_LINES_V",
  API_URL_FOR_VIEW_TRANSACTION_VALIDATE_SHIPMENT: "/supplier/validateShipment/transactions",
  API_URL_FOR_VIEW_TRANSACTION_VALIDATE_INVOICE: "/supplier/validateInvoice/transactions",
  API_URL_FOR_VIEW_SUPPLIER_CONTACTS: "supplier/views/ATS_SUP_CONTACTS_A_V",
  API_URL_FOR_VIEW_SUPPLIER_SITES: "supplier/views/ATS_SUP_SUPPLIER_SITES_A_V",
  API_URL_FOR_VIEW_SUPPLIER_BANK_ACCOUNTS: "supplier/views/ATS_SUP_BANK_ACCOUNTS_A_V",
  API_URL_FOR_VIEW_COLUMN_DETAILS: "supplier/views/ATS_ELIT_COLUMN_DETAILS_V",
  API_URL_FOR_INSERT_UPDATE_USER_ROLES: "/general/userRole/transactions",
  API_URL_FOR_VIEW_OF_USER_CONTACTS: "supplier/views/ATS_SUP_USER_CONTACTS_V",
  API_URL_FOR_VIEW_OF_SUPPLIER_CURRENCY: "supplier/views/ATS_SUP_CURRENCY_V",
  API_URL_FOR_VIEW_SUPPLIER_DETAILS_WITH_ORG_ID:"supplier/views/ATS_ELIT_SUP_OU_DETAILS_V",
  API_URL_FOR_VIEW_SUGGESTED_OTHER_SUPPLIER_DETAILS:"supplier/views/SUGGESTED_OTHER_SUP_DETAILS_V",

  // Sourcing APIs
  //API_URL_FOR_VIEW_SRC_DSH_COUNT: "sourcing/views/ATS_SRC_DSH_COUNT_V",
  API_URL_FOR_VIEW_SRC_DSH_COUNT: "sourcing/views/ATS_SRC_DASH_ALL_COUNT_V",
  //API_URL_FOR_VIEW_BUYER_TABLE_LIST: "sourcing/views/ATS_SRC_DSH_RESULTS_V",
  API_URL_FOR_VIEW_BUYER_TABLE_LIST: "sourcing/views/ATS_SRC_DASH_RESULTS_V",
  API_URL_FOR_TRANSACTIONS_SECTION_DETAILS: "sourcing/sectionDetails/transactions",
  API_URL_FOR_VIEW_SUP_SRC_DSH_COUNT: "sourcing/views/ATS_SRC_SUP_DSH_COUNT_V",
  API_URL_FOR_VIEW_SUPPLIER_TABLE_LIST: "sourcing/views/ATS_SRC_SUP_DSH_RESULTS_V",
  API_URL_FOR_VIEW_ALL_SOURCING_HEADER_V: "sourcing/views/ATS_SRC_DOCUMENT_HEADERS_V",
  API_URL_FOR_VIEW_INVITED_SUPPLIER: "sourcing/views/ATS_ELIT_INVITED_SUPPLIER_V",
  API_URL_FOR_VIEW_SURROGATE_QUOTE: "sourcing/views/ATS_SRC_SURROGATE_QUOTE_V",
  API_URL_FOR_VIEW_REQUIREMENTS: "sourcing/views/ATS_SRC_DOC_SUPPLIER_RQMT_V",
  API_URL_FOR_TRANSACTIONS_CREATE_QUOTE: "sourcing/createQuote/transactions",
  API_URL_FOR_VIEW_SRC_LINES_PAYTERMS: "sourcing/views/ATS_SRC_LINES_PAY_TERMS_V",
  API_URL_FOR_VIEW_SRC_LINES_COST_FACTOR: "sourcing/views/ATS_SRC_LINES_COST_FACTORS_V",
  API_URL_FOR_VIEW_SRC_LINE_ATTRIBUTES: "sourcing/views/ATS_SRC_LINE_ATTRIBUTES_V",
  API_URL_FOR_VIEW_SUPPLIER_RQMT: "sourcing/views/ATS_SRC_SUPPLIER_RQMT_V",
  API_URL_FOR_TRANSACTIONS_RQMT_SOURCING: "sourcing/requirements/transactions",
  API_URL_FOR_VIEW_SOURCING_LINE_DETAILS: "sourcing/views/ATS_SRC_SUPPLIER_LINE_DETAILS_V",
  API_URL_FOR_VIEW_SRC_DOCUMENT_LINES_V: "sourcing/views/ATS_SRC_DOCUMENT_LINES_V",
  API_URL_FOR_VIEW_SRC_DOCUMENT_HISTORY_V: "sourcing/views/ATS_SRC_SUP_HISTORY_V",
  API_URL_FOR_VIEW_SUPPLIER_LIST: "sourcing/views/ATS_SRC_SUPPLIER_LIST_V",
  API_URL_FOR_VIEW_SOURCING_DOCUMENT_RQMT: "sourcing/views/ATS_SRC_DOCUMENT_RQMT_V",
  API_URL_FOR_VIEW_QUOTE_SAVINGS: "sourcing/views/ATS_SRC_QUOTE_SAVINGS_V",
  API_URL_FOR_VIEW_SCORING: "sourcing/views/ATS_SRC_SCORING_V",
  API_URL_FOR_VIEW_TECHNO_COMMERCIAL: "sourcing/views/ATS_SRC_TECHNO_COMMERCIAL_V",
  API_URL_FOR_VIEW_BUYER_EVALUATION: "sourcing/views/ATS_SRC_BUYER_EVALUATION_V",
  API_URL_FOR_TRANSACTIONS_DOCUMENT_HEADERS: "sourcing/documentHeader/transactions",
  API_URL_FOR_TRANSACTIONS_DOCUMENT_AWARD: "sourcing/document/award/transactions",
  API_URL_FOR_VIEW_PO_SUMMARY: "sourcing/views/ATS_SRC_PO_SUMMARY_V",
  API_URL_FOR_TRANSACTIONS_LINE_SCORE_INSERT: "sourcing/lineScoring/insertWithType",
  API_URL_FOR_TRANSACTIONS_LINE_SCORE_UPDATE: "sourcing/lineScoring/updateWithType",
  API_URL_FOR_TRANSACTIONS_LINE_PAYTERMS: "sourcing/linePayTerms/transactions",
  API_URL_FOR_VIEW_SRC_TOTAL_SCORING:"sourcing/views/ATS_SRC_TOTAL_SAVINGS_V",
  // Utility APIs
  API_URL_FOR_VIEW_ORACLE_HR_EMPLOYEE: "utility/views/ATS_HR_EMPLOYEE_V",
  API_URL_FOR_VIEW_UTILITY_GL_RATES: "utility/views/ATS_GL_RATES_V",
  API_URL_FOR_VIEW_UTILITY_BILL_TO_LOC: "utility/views/ATS_PO_BILL_TO_LOC_V",
  API_URL_FOR_VIEW_UTITLITY_SHIP_TO_LOC: "utility/views/ATS_PO_SHIP_TO_LOC_V",
  API_URL_FOR_VIEW_OF_ORACLE_FREIGHT_TERMS: "utility/views/ATS_PO_FREIGHT_TERMS_V",
  API_URL_FOR_VIEW_OF_ORACLE_PAYMENT_TERMS: "utility/views/ATS_AP_TERMS_V",
  API_URL_FOR_TRANSACTIONS_PO_CREATION: "utility/poCreation/transactions",
  // API_URL_FOR_VIEW_UTILITY_PO_SUMMARY: "utility/views/ATS_PO_SUMMARY_V",
  API_URL_FOR_VIEW_UTILITY_PO_SUMMARY: "utility/views/ATS_ELIT_PO_SUMMARY_V",
  API_URL_FOR_VIEW_UTILITY_PO_LINE_DETAILS: "utility/views/ATS_PO_LINE_DETAILS_V",
  API_URL_FOR_VIEW_PO_ITEM_PRICES: "utility/views/ATS_PO_ITEM_PRICES_V",
  API_URL_FOR_VIEW_PO_ITEM_BST_CUR_PRICES: "utility/views/ATS_PO_ITEM_BST_CUR_PRICES_V",
  API_URL_FOR_VIEW_PO_REQ_NUMBER: "utility/views/ATS_PO_REQ_NUMBER_V",
  API_URL_FOR_VIEW_PO_REQ_ITEM_NUMBER: "utility/views/ATS_PO_REQ_ITEM_NUMBER_V",
  API_URL_FOR_VIEW_INV_PO_LINE_DETAILS: "utility/views/ATS_INV_PO_LINE_DETAILS_V",
  API_URL_FOR_VIEW_ORACLE_HR_OPERATING_UNITS: "utility/views/ATS_HR_OPERATING_UNITS_V",
  API_URL_FOR_VIEW_ITEM_CATEGORIES: "utility/views/ATS_PO_ITEM_CATEGORIES_V",
  API_URL_FOR_VIEW_ORACLE_PO_CURRENCY: "utility/views/ATS_PO_CURRENCY_V",
  API_URL_FOR_VIEW_PO_LINE_TYPE: "utility/views/ATS_PO_LINE_TYPES_V",
  API_URL_FOR_VIEW_PO_LINE_ITEMS: "utility/views/ATS_PO_INV_ITEMS_V",
  API_URL_FOR_VIEW_UOM: "utility/views/ATS_PO_UOM_V",
  API_URL_FOR_VIEW_AP_SUPPLIER: "utility/views/ATS_AP_SUPPLIER_V",
  API_URL_FOR_VIEW_SUPPLIER_SITE: "utility/views/ATS_AP_SUPPLIER_SITE_V",
  API_URL_FOR_VIEW_SUPPLIER_CONTACT: "utility/views/ATS_AP_SUPPLIER_CONTACT_V",
  API_URL_FOR_DATA_DUPLICATE_VALIDATION: "utility/check/duplicate",
  // Purchase Requisition API's
  API_URL_FOR_TRANSACTIONS_PURCHASE_REQUISITION_SAVE: "/requisition/headers/transactions",
  API_URL_FOR_TRANSACTIONS_PURCHASE_REQUISITION_LINES: "/requisition/lineDetails/transactions",
  API_URL_FOR_GET_REQUISITION_LINES: "/requisition/views/ATS_REQUISITION_LINES_V",
  API_URL_FOR_TRANSACTIONS_PURCHASE_REQUISITION_RETRIEVE: "/requisition/views/ATS_REQUISITION_HEADERS_V",
  API_URL_FOR_DEPARTMENT_DETAILS: "requisition/budget/views/ATS_ELIT_BUDGET_ACC_DETAILS_V",

  // Catalog API's
  API_URL_FOR_SAVE_UPDATE_CATALOG: "requisition/catalog/headers/transactions",
  API_URL_FOR_SAVE_UPDATE_CATALOG_ITEM: "requisition/catalog/lineDetails/transactions",
  API_URL_FOR_VIEW_CATALOG_HEADER: "requisition/catalog/views/ATS_SUP_CATALOG_HEADERS_V",
  API_URL_FOR_VIEW_CATALOG_ITEM: "requisition/catalog/views/ATS_SUP_CATALOG_ITEMS_V",

  // Purchase Order API's
  API_URL_FOR_SAVE_UPDATE_PURCHASE_ORDER: "/order/headers/transactions",
  API_URL_FOR_SAVE_UPDATE_PURCHASE_ORDER_LINES: "/order/lineDetails/transactions",
  API_URL_FOR_VIEW_PURCHASE_ORDER_HEADER: "order/views/ATS_PO_HEADERS_V",
  API_URL_FOR_VIEW_PURCHASE_ORDER_LINES: "/order/views/ATS_PO_LINES_V",
  API_URL_FOR_VIEW_PURCHASE_ORDER_HEADER_DISCOUNT_PERCENT: "order/views/PO_HEADER_WITH_DISCOUNT_V",
  API_URL_FOR_ATS_ELIT_LOGISTICS_V: "/supplier/views/ATS_ELIT_LOGISTICS_V",
  // API_URL_FOR_VIEW_SUPPLIER_LOOKUP_VALUES: "supplierView/ATS_ELIT_LOOKUP_VALUES_V",
  API_URL_FOR_VIEW_OF_CONTACTS_ASSIGNMENT: "supplier/contactAssignments/transactions",
  API_URL_FOR_VIEW_OF_ALL_CONTACT_ASSIGNMENT: "supplier/views/ATS_SUP_CONTACT_ASSIGNMENTS_V",
  API_URL_FOR_VIEW_APRD_SUPPLIER_CONTACT: "utility/views/ATS_SUPPLIER_CONTACT_V",

  // Inventory API's
  API_URL_FOR_SAVE_UPDATE_INVENTORY: "general/inventory/transactions",

  //Dashboard Action API's
  API_URL_FOR_SAVE_UPDATE_ACTION: "dashboard/action/transactions",
  API_URL_FOR_VIEW_ACTION_DETAILS: "dashboard/views/ATS_ELIT_ACTION_DETAILS_V",
  API_URL_FOR_SAVE_UPDATE_ROLE_ACTION: "dashboard/roleAction/transactions", //Added by Praveen
  API_URL_FOR_VIEW_ROLE_ACTION_DETAILS: "dashboard/views/ATS_ELIT_ROLE_ACTION_DETAILS_V", //Added by Praveen
  API_URL_FOR_VIEW_USER_ACTION_DETAILS: "/dashboard/views/ATS_ELIT_USER_ACTION_DETAILS_V",
  API_URL_FOR_SAVE_UPDATE_USER_ACTION: "/dashboard/userAction/transactions",

  // Contract Management API's
  API_URL_FOR_GET_CONTRACT_DASHBOARD_DATA: "contracts/views/ATS_CONTRACT_DSH_COUNT_V",
  API_URL_FOR_GET_CONTRACT_DASHBOARD_TABLE_DATA: "contracts/views/ATS_CONTRACT_SUMMARY_V",
  API_URL_FOR_CREATE_CONTRACT_SAVE_SUMMARY_DATA: "contracts/summary/transactions",
  API_URL_FOR_CREATE_CONTRACT_SAVE_MILESTONE_DATA: "contracts/milestone/transactions",
  API_URL_FOR_CREATE_CONTRACT_PROJECT_LIST_DATA: "general/views/ATS_ELIT_PROJECTS_V",
  API_URL_FOR_CREATE_CONTRACT_TEMPLATE_DATA: "general/views/ATS_ELIT_TEMPLATE_V",
  API_URL_FOR_CREATE_CONTRACT_MILESTONE_DATA: "contracts/views/ATS_CONT_MILESTONE_V",
  API_URL_FOR_CREATE_CONTRACT_TEMPLATE_LINK: "contracts/linkTemplate/transactions",
  API_URL_FOR_CREATE_CONTRACT_SAVE_ATTACHMENT: "general/attachments/transactions",
  API_URL_FOR_CREATE_CONTRACT_TEMPLATE_RETRIEVE_DATA: "contracts/views/ATS_CONTRACT_TEMPLATE_V",

  // Process Flow API's
  API_URL_FOR_RETRIEVE_PROCESS_FLOW_VIEW: "processflow/views/ATS_ELIT_PROCESS_FLOW_V",

  // Requisition Dashboard API's
  API_URL_FOR_RETRIEVE_REQUISITION_STATUS_CHART: "requisition/views/ATS_REQ_DSH_COUNT_V",
  API_URL_FOR_RETRIEVE_REQUISITION_EMERGENCY_CHART: "requisition/views/ATS_REQ_EMERG_FLAG_COUNT_V",
  API_URL_FOR_RETRIEVE_REQUISITION_TYPE_CHART: "requisition/views/ATS_REQ_TYPE_COUNT_V",
  API_URL_FOR_RETRIEVE_RESULT_TABLE: "requisition/views/ATS_REQ_DSH_RESULT_V",
  API_URL_FOR_RETRIEVE_REQUESTOR_CHART_COUNT: "requisition/views/ATS_REQ_USER_COUNT_V",

  // Purchase Order Dashboard API's
  API_URL_FOR_RETRIEVE_ORDER_STATUS_COUNT: "order/views/ATS_PUR_DSH_COUNT_V",
  API_URL_FOR_RETRIEVE_RESULTS_DATA: "order/views/ATS_PUR_DSH_RESULTS_V",
  API_URL_FOR_RETRIEVE_BUYER_STATUS_COUNT: "order/views/ATS_PUR_USER_COUNT_V",
  API_URL_FOR_RETRIEVE_OPERATING_UNIT_CHART_DATA: "order/views/ATS_PUR_DSH_OU_COUNT_V",
  API_URL_FOR_RETRIEVE_ORDER_TYPE_CHART_COUNT: "order/views/ATS_PUR_DSH_ORD_TYP_COUNT_V",
  API_URL_FOR_RETRIEVE_BUYER_TOTAL_SPENT_STATUS_COUNT: "order/views/ATS_PUR_DSH_AMT_COUNT_V",
  // Purchase Order Dashboard API's for Manager
  API_URL_FOR_RETRIEVE_BUYER_COUNT: "/order/views/ATS_SPEND_BUYER_COUNT_V",
  API_URL_FOR_RETRIEVE_MONTH_COUNT: "/order/views/ATS_SPEND_MONTH_COUNT_V",
  API_URL_FOR_RETRIEVE_CATEGORY_COUNT: "/order/views/ATS_SPEND_CATEGORY_COUNT_V",
  API_URL_FOR_RETRIEVE_SUPPLIER_COUNT: "/order/views/ATS_SPEND_SUPPLIER_COUNT_V",
  // Approval Screen API's
  API_URL_FOR_APPROVAL_RULES_VIEW: "approval/views/ATS_ELIT_APPROVAL_RULES_V",
  API_URL_FOR_CREATE_NEW_RULE: "approval/apprules/transactions",
  API_URL_FOR_GET_ATTRIBUTES_LIST: "approval/views/ATS_ELIT_APPROVAL_ATTRIBUTES_V",
  API_URL_FOR_GET_CONDITIONS_LIST: "approval/views/ATS_ELIT_APPROVAL_CONDITIONS_V",
  API_URL_FOR_SAVE_CONDITIONS_DETAILS: "approval/appconditions/transactions",
  API_URL_FOR_CREATE_AND_SAVE_APPROVER: "approval/appdetails/transactions",
  API_URL_FOR_GET_APPROVERS_LIST: "approval/views/ATS_ELIT_APPROVER_DETAILS_V",

  // RFQ Dashboard API's
  API_URL_FOR_GET_SOURCING_STATUS_COUNT: "sourcing/views/ATS_SRC_DASH_COUNT_V",
  API_URL_FOR_GET_BUYER_COUNT: "sourcing/views/ATS_SRC_DASH_BUY_COUNT_V",
  API_URL_FOR_GET_OPERATING_UNIT_COUNT: "sourcing/views/ATS_SRC_DASH_OU_COUNT_V",
  API_URL_FOR_GET_TWO_STAGE_COUNT: "sourcing/views/ATS_SRC_DASH_STAGE_COUNT_V",
  API_URL_FOR_GET_TOP_THREE_SUPPLIERS_COUNT: "sourcing/views/ATS_SRC_DASH_SUP_COUNT_V",
  API_URL_FOR_GET_TABLE_RESULTS_DATA: "sourcing/views/ATS_SRC_DASH_RESULTS_V",

  // Contract Dashboard API's
  API_URL_TO_GET_CONTRACT_STATUS_CHART_COUNT: "contracts/views/ATS_CONT_DSH_COUNT_V",
  API_URL_TO_GET_CONTRACT_TYPE_CHART_COUNT: "contracts/views/ATS_CONT_DSH_TYP_COUNT_V",
  API_URL_TO_GET_CONTRACT_OPERATING_UNIT: "contracts/views/ATS_CONT_DSH_OU_COUNT_V",
  API_URL_TO_GET_CONTRACT_REQUESTER_COUNT: "contracts/views/ATS_CONT_DSH_REQU_COUNT_V",
  API_URL_TO_GET_TABLE_RESULTS_DATA: "contracts/views/ATS_CONT_DSH_RESULTS_V",
  //   //Mix

  //  // Purchase Order API's
  //  API_URL_FOR_SAVE_UPDATE_PURCHASE_ORDER:"/order/headers/transactions",
  //  API_URL_FOR_SAVE_UPDATE_PURCHASE_ORDER_LINES:"/order/lineDetails/transactions",
  //  API_URL_FOR_VIEW_PURCHASE_ORDER_HEADER:"/order/views/AT_PO_HEADERS_V",
  //  API_URL_FOR_VIEW_PURCHASE_ORDER_LINES:"/order/views/ATS_PO_LINES_V",

  API_URL_FOR_VIEW_SOURCING_ATS_SRC_DOCUMENT_TEAM: "sourcing/views/ATS_SRC_DOCUMENT_TEAM_V",
  // API_URL_FOR_VIEW_OF_SOURCING_ATS_PO_TYPE: "sourcing/views/ATS_SRC_DASH_BUY_COUNT_V",
  API_URL_FOR_VIEW_OF_SOURCING_ATS_PO_TYPE: "sourcing/views/ATS_PO_TYPE_V",



  API_URL_FOR_VIEW_OF_SUPPLIER_VIEW_COLUMN_DETAILS: "/general/views/ATS_ELIT_COLUMN_DETAILS_V",
  API_URL_FOR_VIEW_ACTION_ASSIGN: "general/rolesAndPrivileges/ATS_ELIT_ACTION_ASSIGNMENTS_V",

  API_URL_FOR_VIEW_OF_USER: "general/views/ATS_ELIT_USER_V",

  API_URL_FOR_VIEW_AWARD_DETAILS: "sourcing/views/ATS_SRC_GET_AWARD_DETAILS_V",

  API_URL_FOR_VIEW_ORACLE_PO_REQUISITION: "utility/views/ATS_PO_REQUISITION_V",

  API_URL_FOR_VIEW_PA_PROJECTS: "utility/views/ATS_PA_PROJECTS_V",

  API_URL_FOR_TRANSACTIONS_NEG_TEAM_MEMBERS: "sourcing/teamMembers/transactions",


  API_URL_FOR_TRANSACTIONS_DOCUMENT_LINE_DETAILS: "sourcing/documentLineDetails/transactions",
  API_URL_FOR_TRANSACTIONS_INSERT_UPDATE_DELETE_OF_PRIVILAGE_ACTION_ASSIGN: "rolesAndPrivilages/ATS_ELIT_PRIVILAGE_ACTION_ASSIGNMENT",

  API_URL_FOR_TRANSACTIONS_INSERT_UPDATE_DELETE_USER: "rolesAndPrivilages/ATS_ELIT_USER",

  API_URL_FOR_TRANSACTIONS_SRC_LINE_ATTRIBUTES: "sourcing/srcLineAttributes/transactions",
  API_URL_FOR_TRANSACTIONS_SRC_LINES_COST_FACTOR: "srcLineCostFactors/transactions",
  API_URL_FOR_TRANSACTIONS_AUTH_FORGOT_PASSWORD: '/auth/forgetPassword',

  API_URL_FOR_IMAGE_UPLOAD: 'uploadImage',
  // Product & Services
  API_URL_FOR_INSERT_UPDATE_DELETE_PRODUCT_CATEGORY:'general/productCategory/transactions',
  API_URL_FOR_INSERT_UPDATE_DELETE_PRODUCT_SUB_CATEGORY:'general/productSubCategory/transactions',
  // Terms and Condition
  API_URL_FOR_VIEW_OF_TERMS_AND_CONDITIONS: "general/views/ATS_ELIT_TERMS_AND_CONDITIONS_V",
  API_URL_FOR_INSERT_UPDATE_TERMS_AND_CONDITIONS: "general/termsConditions/transactions",
  API_URL_FOR_SAVE_UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE:"general/acceptance/transactions",
  API_URL_FOR_VIEW_TERMS_AND_CONDITIONS_ACCEPTANCE_STATUS:"general/views/ATS_ELIT_TAC_ACCEPTANCE_STATUS_V",
  API_URL_FOR_VIEW_TERMS_AND_CONDITIONS_ACCEPTANCE:"general/views/ATS_ELIT_TAC_ACCEPTANCE_V",
  //Company Registration Api's
  API_URL_FOR_INSERT_UPDATE_COMPANY_REGISTRATION:"general/operatingUnit/transactions",
  API_URL_FOR_INSERT_UPDATE_EMPLOYEE_DETAILS:"general/employees/transactions",
  API_URL_FOR_INSERT_UPDATE_EMPLOYEE_LOCATION_DETAILS:"general/locations/transactions",
  API_URL_FOR_VIEW_OF_EMPLOYEE_ROLE:"general/views/ATS_ELIT_COMP_ROLE_V",
  API_URL_FOR_VIEW_OF_COMPANY_LOCATIONS_VIEW:"general/views/ATS_ELIT_LOCATIONS_V",
  // GHS
  API_URL_FOR_INSERT_UPDATE_PROJECTS:"general/projects/transactions",
  API_URL_FOR_VIEW_OF_PROJECTS:"general/views/ATS_ELIT_GHS_PROJECTS_V",
  //PR and PO Excel Sheets Download
  API_URL_FOR_PR_LINE_EXCEL_SHEET_DOWNLOAD:"excelSheet/prLine/download/",
  API_URL_FOR_PO_LINE_EXCEL_SHEET_DOWNLOAD:"excelSheet/poLine/download/",
  API_URL_FOR_CREATING_INVENTORY_EXCEL_SHEET_DOWNLOAD:"excelSheet/inventory/download/",
  API_URL_FOR_RFQ_EXCEL_SHEET_DOWNLOAD:"excelSheet/sourcing/download/",
  API_URL_FOR_PROJECTS_EXCEL_SHEET_DOWNLOAD:"excelSheet/project/download",
  API_URL_FOR_SUBMIT_QUOTE_LINES_EXCEL_SHEET_DOWNLOAD:"excelSheet/createQuote/download/",
  //Reports
  API_URL_FOR_GENERATE_PO_REPORT:"queryReports/purchase/",
  API_URL_FOR_GENERATE_PR_REPORT:"queryReports/requisition/",
  API_URL_FOR_GENERATE_RFQ_REPORT:"queryReports/sourcing/RFQ/",
  API_URL_FOR_GENERATE_AUCTION_REPORT:"queryReports/sourcing/AUCTION/",
  API_URL_FOR_GENERATE_SHIPMENT_REPORT:"queryReports/shipment/",
  API_URL_FOR_GENERATE_INVOICE_REPORT:"queryReports/invoice/",
  API_URL_FOR_GENERATE_PROJECT_CATALOG_REPORT:"queryReports/projectCatalog/",
  API_URL_FOR_GENERATE_SUPPLIER_REG_REPORT:"queryReports/supplierRegistration/",
  API_URL_SENDING_EMAIL_FOR_CATEGORY:"general/productSubCategory/emailNotification/CategorySubCategory",
  API_URL_FOR_DOWNLOADING_BAFO_REPORT: "report/BAFO/",
  API_URL_FOR_DOWNLOADING_BAFO_DETAILS: "report/BAFO_DETAILS/",
  API_URL_FOR_SHOWING_REQUISITION_NUMBER_DISPLAY: "queryReports/ATS_REQUISITION_NUMBER_DISPLAY",
  API_URL_FOR_SHOWING_PO_NUMBER_DISPLAY: "queryReports/ATS_PURCHASE_ORDER_DISPLAY",
  API_URL_FOR_GENERATE_BUDGET_STATUS_REPORT: "queryReports/budgetStatus/",
  API_URL_FOR_GENERATE_RECEIVING_TRANSACTIONS_REPORT: "queryReports/receiving/",
  API_YRL_FOR_GENERATE_PAYMENT_TRACKING_REPORT: "queryReports/paymentTracking/",
  // Client PO Dashboard APIs
  API_URL_FOR_GET_PO_STATUS_COUNT:"dashboard/po/views/ATS_PO_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_PO_APPROVAL_STATUS_COUNT:"dashboard/po/views/ATS_PO_ARPV_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_PO_SUPPLIER_STATUS_COUNT:"dashboard/po/views/ATS_PO_SUP_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_PO_BUYER_STATUS_COUNT:"dashboard/po/views/ATS_PO_BUYER_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_SHIPMENT_STATUS_COUNT:"dashboard/po/views/ATS_SHIP_STATUS_COUNT_V",
  API_URL_FOR_GET_INVOICE_STATUS_COUNT:"dashboard/po/views/ATS_INV_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_PO_SHIPMENT_STATUS:"dashboard/po/views/ATS_PO_SHIP_DSH_STATUS_V",
  API_URL_FOR_GET_PO_INVOCICE_STATUS:"dashboard/po/views/ATS_PO_INV_DSH_STATUS_V",
  API_URL_FOR_GET_PO_RESULT:"dashboard/po/views/ATS_PO_RESULTS_DSH_V",
  API_URL_FOR_GET_PO_SHIPMENT_RESULT:"dashboard/po/views/ATS_PO_SHIP_RESULTS_DSH_V",
  API_URL_FOR_GET_PO_INVOICE_RESULT:"dashboard/po/views/ATS_PO_INV_RESULTS_DSH_V",
  // Client PR Dashboard APIs
  API_URL_FOR_GET_PR_STATUS_COUNT:"dashboard/pr/views/ATS_PR_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_PR_APPROVAL_STATUS_COUNT:"dashboard/pr/views/ATS_PR_APRV_DSH_COUNT_V",
  API_URL_FOR_GET_PR_REQUEST_COUNT:"dashboard/pr/views/ATS_PR_REQUEST_DSH_COUNT_V",
  API_URL_FOR_GET_PR_SOURCING_COUNT:"dashboard/pr/views/ATS_PR_SRC_DSH_COUNT_V",
  API_URL_FOR_GET_PR_PO_COUNT:"dashboard/pr/views/ATS_PR_PO_DSH_COUNT_V",
  API_URL_FOR_GET_PR_RESULT:"dashboard/pr/views/ATS_PR_RESULTS_DSH_V",
  // Client Sourcing Dashboard APIs
  API_URL_FOR_GET_SRC_STATUS_COUNT:"dashboard/src/views/ATS_SRC_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_BUYER_STATUS_COUNT:"dashboard/src/views/ATS_SRC_BUYER_STS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_SUPPLIER_STATUS_COUNT:"dashboard/src/views/ATS_SRC_SUP_STATUS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_EVALUATION_STATUS_COUNT:"dashboard/src/views/ATS_SRC_EVALUATION_STS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_AWARDING_PROGRESS_STATUS_COUNT:"dashboard/src/views/ATS_SRC_AWARD_PRG_STS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_AWARD_APPROVAL_STATUS_COUNT:"dashboard/src/views/ATS_SRC_AWARD_APRV_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_AWARDED_STATUS_COUNT:"dashboard/src/views/ATS_SRC_AWARDED_STS_DSH_COUNT_V",
  API_URL_FOR_GET_SRC_RESULT:"dashboard/src/views/ATS_SRC_RESULTS_DSH_V",
}

export default ApiList

/**

Version      Jira              Who              Comments
----------------------------------------------------------------------------------------------------------------------
1.1         ELIT-110           Muzamil          Data Validation issue, New api added for checking already existing data.
1.1         ELIT-171           Muzamil          Node-schedule for GL Rate API
1.1         ELIT-290           Praveen          Changes required in PO and PR
1.1         ELIT-299/ATS-10    Praveen          Incorporate PO Acceptance
1.1         ELIT-303/ATS-28    Praveen          Dashboard for Sourcing and Purchase Order
1.1         ELIT-305           Praveen          Change Password
1.1         ELIT-323           Praveen          Product and Services
1.1         ATS-7              Vinay            Terms and Condition
**/