module.exports = Object.freeze({
  APIList: {
    SUPPLIER_HEADER: {
      URL: "supplierHeader/transicon-s",
      ACTION1_SUBMIT_QUOTE: "SubmitQuote",
      ACTION2_UPDATE_TOTAL: "UpdateTotal"
    },
    SUPPLIER_LINE_DETAILS_V: {
      URL: "sourcing/ATS_SRC_SUPPLIER_LINE_DETAILS_V"
    },
    LINES: {
      SUPPLIER_LINE_DETAILS_SAVE: {
        URL: "supplierLineDetails/transactions",
        ACTION1_INSERT_UPDATE: "InsertOrUpdateSupLines"
      },
      SUPPLIER_LINE_ATTRIBUTE_SAVE: {
        URL: "supplierLineAtt/transactions",
        ACTION1_INSERT_UPDATE: "Insert_or_Update_Supplier_Line_Attributes"
      },
      SUPPLIER_LINE_COST_FACTOR_SAVE: {
        URL: "srcSupplierLineCostFactor/transactions",
        ACTION1_INSERT_UPDATE: "INSERT_OR_UPDATE_SRC_SUP_LINES_COST_FACTORS"
      },
      SUPPLIER_LINE_COST_FACTOR_DELETE: {
        URL: "srcSupplierLineCostFactor/transactions",
        ACTION1_INSERT_UPDATE: "DeleteCostFactor"
      }
    }
  },
  CONFIGURATION: {
    DATE_FORMAT: ["d-MMM-yyyy", "dd-MM-yyyy",],
    DATE_TIME_FORMAT: "dd-MM-yyyy h:mm aa",  //before value ("d-MMM-yyyy h:mm aa") cannot add array for datetimepicker,
    DATE_MONTH_TIME_FORMAT: "dd-MMM-yyyy h:mm aa",  //before value ("d-MMM-yyyy h:mm aa") cannot add array for datetimepicker,
    FILE_SIZE_LIMIT: 2,
    FILE_EXTENSION_ALLOWED: ["pdf", "doc", "docx", "xls", "xlsx", "csv", "txt", "rtf", "html", "zip", "jpg", "jpeg", "png", "gif"],
    FILE_EXTENSION_ALLOWED_IMAGE: ["jpg", "jpeg", "png", "gif"],
    FILE_EXTENSION_ALLOWED_IMAGE_LOGO: ["png"],
    MINIMUM_ESTABLISHMENT_YEAR: new Date("1950"), //Don't prefer this
    MIN_START_DATE: new Date("1950"),//Don't prefer this
    MIN_DATE: new Date("1950"),
    MAX_DATE: new Date("2080"),
    DEFAULT_COUNTRY: {NAME: "in", CODE: "+91"},
    DOCUMENT_LINES_QUANTITY_LIMIT: 5,
    REQUISITION_LINES_QUANTITY_LIMIT: 9
  },
  MESSAGES_SCREEN: {
    REQUIRED_ALL_LINES: "This document required all lines price!",
    MINIMUM_ONE_LINE: "This document required minimum one line price!",
    ONLY_SINGLE_AWARD: "You can award to single supplier only!",
  },
  MAX_LENGTH: {
    PHONE_NUMBER_LENGHT: 15,
    LICENSE_NUMBER_LENGTH: 21,
  },
  COST_FACTOR_PRICING: {
    UNIT: "Per Unit",
    PER_LINE: "% of Line Price",
    FIXED_AMT: "Fixed Amount",
  },
  icons: [
    // 'fa-pie-chart',
    // "fa-cubes",
    // "fa-th-large",
    // "fa-thumbs-o-up",
    // "fa-solid fa-gear",
    // "fa-solid fa-gears",
    // "fa-solid fa-gears",

    "icon-action",
    "icon-admin",
    "icon-approval",
    "icon-approvals",
    "icon-assignment",
    "icon-attribute-1",
    "icon-auction",
    "icon-award",
    "icon-awarding",
    "icon-buyer",
    "icon-catalogue",
    "icon-close",
    "icon-closed",
    "icon-commercial",
    "icon-contacts",
    "icon-contract-management",
    "icon-contract-management-2",
    "icon-dashboard",
    "icon-extend",
    "icon-extend2",
    "icon-free-shipping",
    "icon-inventory",
    "icon-invoice",
    "icon-logout",
    "icon-lookups",
    "icon-male-user",
    "icon-menu",
    "icon-module",
    "icon-notification",
    "icon-pages",
    "icon-publish-publishing",
    "icon-purchase_order_2",
    "icon-purchase-order",
    "icon-requisition",
    "icon-rfq",
    "icon-rfq-2",
    "icon-role",
    "icon-shipment",
    "icon-supplier",
    "icon-team",
    "icon-technology",
    "icon-user-128",
  ],
  TOAST_MESSAGE_KEY: {
    DECLINE_TAC: "DECLINE_T&C",
  },
  SQL_TOKEN: "",
  ERP_TOKEN: "",
  COMPANY_LOGO_URL: JSON.parse(localStorage.getItem("companyLogo")),
  LOGIN_VALID_TIME_MINUTES: 5,
  LOGIN_REFRESH_TIME_MINUTES: 4,
  AES: {
    KEY: "8479768f48481eeb9c8304ce0a58481e",
    IV_LENGTH: 16,
    ALGORITHM: 'aes-256-cbc'
  },
});
/**
Version      Jira             Who              Comments
----------------------------------------------------------------------------------------------------------------------
1.1         ELIT-272/ATS-1    Praveen	         Supplier Registration and Supplier Profile- The Account Number should allow zero in prefix
**/