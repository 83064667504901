import axios from 'axios';
import constFunction from '../utils/const.function';
import configFile from '../config.json';
import download from 'downloadjs'
import ApiList from '@ApiList';

class ApiProvider {
  constructor() {

    var apiURL = ""

    // var url = window.location.href
    // var arr = url.split("/")
    // var array = arr[2]
    // var arrayy = array.split(":")
    // var portNum = parseInt(arrayy[1])
    // apiURL = `${configFile[configFile.MODE + "_URL"]}/api/`
    apiURL = configFile.MODE === "LOCALHOST" ? `${configFile[configFile.MODE + "_URL"]}/` : `${configFile[configFile.MODE + "_URL"]}/api/`
    // apiURL = `${configFile[configFile.MODE + "_URL"]}:${4915}/`

    this.api = axios.create({
      baseURL: apiURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  async auth(url, data) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        // var token = constFunction.getAuthToken(url, data)
        // if (token !== null) {
        //   this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // }
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + 'ELIT' + data;
        if (ApiList.API_URL_FOR_LOGIN === url) {
          data = {}
        }
        const reqBody = {
          body: data,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        let temp = url === "auth/init" ? reqBody : finalReqBody
        this.api.post(url, temp).then(response => {
          if (constFunction.getStatusCode(response.data) === 200) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          }
        })
      })
    })
  }
  async post(url, data) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        // var token = constFunction.getAuthToken(url, data)
        // if (token !== null) {
        //   this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // }
        if(JSON.parse(localStorage.getItem("userinfo")) !== null) {
          let instance = JSON.parse(localStorage.getItem("userinfo"))
          this.api.defaults.headers.common['Authorization'] = 'Bearer ' + "ELIT" + instance.token;
        }
        const reqBody = {
          body: data,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        // this.api.post(url, reqBody).then(response => {
        const finalReqBody = {
          body : encryptedPayload
        }
          this.api.post(url, finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 200) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            // resolve(constFunction.getBody(response.data))
            resolve(constFunction.getBody(decryptedData))
          } else {
            constFunction.getStatusMessage(response.data).then(result => {
              constFunction.showToast("error", result)
              resolve("error")
            })
          }
        })
          .catch(errorHandling => {
            resolve({
              DML_STATUS: "E"
            })
          });
      })
    })
  }
  async get(url, data) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        var token = constFunction.getAuthToken(url, data)
        if (token !== null) {
          this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
        const reqBody = {
          body: data,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        this.api.get(url, finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 200) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          } else {
            constFunction.getStatusMessage(response.data).then(result => {
              constFunction.showToast("error", result)
              resolve("error")
            })
          }
        })
          .catch(errorHandling => {
            resolve({
              DML_STATUS: "E"
            })
          });
      })
    })
  }
  async post_createSupplier(url, request, data) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        // var token = constFunction.getAuthToken(url, data)
        // if (token !== null) {
        //   token = token + "ELIT" + data
        //   this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // }
        let token = "ELIT" + data
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const reqBody = {
          body: request,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        this.api.post(url, finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 200) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          } else {
            constFunction.getStatusMessage(response.data).then(result => {
              constFunction.showToast("error", result)
              resolve("error")
            })
          }
        })
          .catch(errorHandling => {
            resolve({
              DML_STATUS: "E"
            })
          });
      })
    })
  }

  async createSupplier(data, body) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + data;
        const reqBody = {
          body: body,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        this.api.post("supplierRegistration/transactions", finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 201) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          } else {
            resolve({
              DML_STATUS: "E",
              DML_MESSAGE: "Please contact to administrative!"
            })
          }
        })
          .catch(errorHandling => {
            resolve(errorHandling)
          });
      })
    })
  }
  async post_resetPassword(url, request, data) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        // var token = constFunction.getPasswordToken(url, data)
        // if (token !== null) {
        //   // token = token + "ELIT" + data
        //   // Commented the above CODE because we are getting the concatenated "token" from the constFunction.getPasswordToken so no need to concatenate again
        //   this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // }
        let token = "ELIT" + data
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        const reqBody = {
          body: request,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        this.api.post(url, finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 200) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          } else {
            constFunction.getStatusMessage(response.data).then(result => {
              constFunction.showToast("error", result)
              resolve("error")
            })
          }
        })
          .catch(errorHandling => {
            resolve({
              DML_STATUS: "E"
            })
          });
      })
    })
  }
  async resetPassword(data, body) {
    return new Promise((resolve, reject) => {
      constFunction.getReqConfig().then(config => {
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + data;
        const reqBody = {
          body: body,
          config: config
        }
        let encryptedPayload = constFunction.encryptAESText(JSON.stringify(reqBody))
        const finalReqBody = {
          body : encryptedPayload
        }
        this.api.post("rolesAndPrivilages/ATS_ELIT_USER", finalReqBody).then(response => {
          if (constFunction.getStatusCode(response.data) === 201) {
            let decryptedData = JSON.parse(constFunction.decryptAESText(response.data.body))
            resolve(constFunction.getBody(decryptedData))
          } else {
            resolve({
              DML_STATUS: "E",
              DML_MESSAGE: "Please contact to administrative!"
            })
          }
        })
          .catch(errorHandling => {
            resolve(errorHandling)
          });
      })
    })
  }
  async downloadFile(url, fileName) {
    return new Promise((resolve, reject) => {
      this.api.get(url,
        {responseType: 'blob'}).then(response => {
          const content = response.headers['content-type'];
          download(response.data, fileName, content)
          resolve("success")
        })
    })
  }
  async getBufferData(url) {
    try {
      const response = await this.api.get(url, { responseType: 'arraybuffer' });
      const bufferData = Buffer.from(response.data);
      return bufferData;
    } catch (error) {
      throw error;
    }
  }
}

const api = new ApiProvider();
export default api;
/**
Version      Jira              Who              Comments
----------------------------------------------------------------------------------------------------------------------
1.1          ELIT-305          Praveen          Change Password
**/